<template>
  <!-- Desc: View: product & category targeting for - Prouct targeting adgroups  -->
  <div
    class="adGroupsTargetingKeyword card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <component
      :is="addCustomKeywordPanelConfig.ui_component"
      v-if="showAddCustomKeywordPanel"
      v-bind="addCustomKeywordPanelConfig.props"
      v-on="addCustomKeywordPanelConfig.events"
    />
    <CustomRecommendations
      v-else-if="
        Object.keys(recommendationPropsAndEvents.props.metadata).length !== 0
      "
      v-bind="recommendationPropsAndEvents.props"
      v-on="recommendationPropsAndEvents.events"
    />
    <div v-if="adjustBids">
      <div class="u-font-size-6 u-spacing-p-l">
        <div class="u-spacing-pb-xs u-text-case-upper u-font-weight-600">
          ADJUST BID ({{ selections.length }} KEYWORD{{
            selections.length > 1 ? `'s` : ''
          }}
          selected)
        </div>
        <radioSelectionWithInput
          class="u-spacing-pt-m"
          :radio-config="bidChangeConfig"
          default-radio-selected="set_new_bid"
          :bid-suggestion="bidSuggestion"
          @onUpdate="onBidUpdates"
        />
      </div>
      <div
        class="u-spacing-pv-m u-border-top u-border-color-grey-xxx-light u-border-width-s"
      >
        <footerButtons
          :disable-left-btn="
            !(bidValue && bidValue.inputValue && bidValue.inputValue.length)
          "
          class="u-spacing-ph-l"
          :hollow-hover-button-left="false"
          left-button-type="filled"
          btn-left-text="Apply"
          :button-size="'s'"
          @onClickBtnRight="closeActionBar"
          @onClickBtnLeft="onSaveBid"
        />
      </div>
    </div>
    <div
      v-if="selections.length && !adjustBids"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="allowedActions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <dashboard-inflator
      class="u-width-100"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :page="pageInfo"
      :request-params="requestParams"
      :page-context="context"
      :widget-level-settings="headerOptions"
      :show-header="false"
      :disable-envelop-width-class="true"
      @metadataUpdated="metadataUpdated"
      @widgetParamsChanged="widgetParamsChanged"
      @widgetParamsCollectionUpdated="widgetParamsCollectionUpdated"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import dashboardServiceTable from '@/components/widgets/custom_widgets/custom_widget_components/tables/dashboardServiceTable.vue';
import CustomRecommendations from '@/pages/dashboard_service_campaign_management/common_components/custom-recommendation.vue';
import customActionPanel from '@/components/ams/actionPanels/customActionPanel.vue';
import dashboardMixin from '@/components/widgets/custom_widgets/dashboard_mixin.js';
import {
  deepReplaceObjectValues,
  updateExistingWidgetParams,
  saveExistingWidgetParams,
  findDimensionValueFromWidgetParams,
  adjustPayloadForTargets
} from '@/pages/entity_details/common_components/helper.js';
import { cloneDeep } from 'lodash';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
import { spAddKeywordsRecommendationComponentConfig } from '@/pages/entity_details/configs/amazon/recommendations-config.js';
const actionsBar = () => import('@/components/widgets/actionsBar.vue');
const footerButtons = () =>
  import('@/pages/entity_details/common_components/footerButtons.vue');

const commonRequestParams = (vueRef) => ({
  adgroup_sp_tab_keywordtargeting: {
    ':widget': 'adgroup_sp_tab_keywordtargeting',
    ':orderByList': [
      {
        dimension: 'ams_cm_targeting_spend',
        direction: 'DESC'
      }
    ],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  },
  adgroup_sp_tab_keyword_recommendation: {
    ':widget': 'adgroup_sp_tab_keyword_recommendation',
    ':orderByList': [],
    ':dimensionNameValueList': [],
    keepExistingDimensionNameValueList: true,
    replaceEntityInDimensionNameValueList: {
      ':adgroup_id': vueRef.entityId
    },
    replaceEntityInOperationsObject: {
      ':system': `campaign_${vueRef.parentEntityId}`
    }
  }
});

export default {
  components: {
    actionsBar,
    radioSelectionWithInput,
    footerButtons,
    CustomRecommendations
  },
  mixins: [dashboardMixin],
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    }
  },
  data() {
    const pageInfo = {
      name: this.tabMetadata.page
    };
    const context = this;
    return {
      pageInfo,
      initRequestParams: {
        global: {
          ...pageInfo,
          ':page': pageInfo.name,
          ':entityType': '#ALLOVER_AGGREGATE'
        }
      },
      recFilter: '',
      recCustomComponent:
        this.entityConfig.tabs[this?.tabMetadata?.component]
          ?.recCustomComponent,
      showRecCustomComponent: false,
      preferNoRecFilterList: [
        'RECOMMENDATIONS_KEYWORDS_FOR_SKUS',
        'RECOMMENDATIONS_KEYWORDS_EFFICIENT_SEARCHTERMS',
        'RECOMMENDATIONS_KEYWORDS_CAMPAIGN_BESTPRACTICE'
      ],
      selectedRecommendation: {},
      isCustomActionStateVisible: false,
      context,
      bidValue: null,
      showAddCustomKeywordPanel: false,
      bidChangeConfig: [
        {
          title: 'Set new bid',
          type: 'input',
          value: 'set_new_bid'
        },
        {
          title: 'Increase bid',
          value: 'increase_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        },
        {
          title: 'Decrease bid',
          value: 'decrease_bid',
          type: 'dropdown',
          dropdown: [
            {
              label: 'Change by percentage',
              value: 'percentage'
            },
            {
              label: 'Change absolute value',
              value: 'absolute_value'
            }
          ]
        }
      ],
      getState: null,
      defaultSearchText: '',
      selections: [],
      adjustBids: null,
      stateChangeRequestPayload: null,
      bidChangeRequestPayload: null,
      updateMetadata: null,
      tableGridAPI: null,
      actionIdMethodMap: {},
      allowedActions: [
        {
          icon: 'task-status',
          title: 'EDIT STATUS',
          id: 'editStatus',
          dropDown: true,
          dropDownOptions: [
            { title: 'Enable', value: 'enabled', color: 'u-color-green-base' },
            { title: 'Pause', value: 'paused', color: 'u-color-orange-base' },
            { title: 'Archive', value: 'archived', color: 'u-color-red-base' }
          ]
        },
        {
          icon: 'dollar',
          title: 'Adjust Bid',
          id: 'adjustBids'
        }
      ],
      bidSuggestion: { show: false },
      tabConfig: null,
      recommendationWidgetParams: {},
      recommendationMetadata: {}
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    recommendationPropsAndEvents() {
      return {
        props: {
          footerOptions: [],
          showLastUpdatedDate: false,
          entityName: 'keyword',
          headingName: 'RECOMMENDATIONS TO ADD/ARCHIVE/ADJUST KEYWORDS',
          countKey: 'ams_cm_recommendation_count',
          labelKey: 'ams_cm_recommendation_type',
          recCustomComponent:
            (this.showRecCustomComponent &&
              this.recCustomComponent(this)[this?.recFilter?.value]) ||
            {},
          selectedRecommendation: this.selectedRecommendation,
          isCustomActionStateVisible: this.isCustomActionStateVisible,
          widgetRequestParams: this.recommendationWidgetParams,
          metadata: this.recommendationMetadata
        },
        events: {
          recommendationFilter: this.recommendationFilter,
          handleRecommendation: this.handleRecommendation
        }
      };
    },
    getAdGroupInfo() {
      return this.$store.getters[this.tabConfig?.apiConfigs?.getter];
    },
    campaignInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      const that = this;
      let buttonHeaderOption = [
        {
          ui_component: 'rb-button',
          props: {
            clickFn: that.addCustomKeywordButtonClick,
            text: 'Add Custom Keywords',
            type: 'filled'
          },
          class: 'u-spacing-mr-xl'
        }
      ];

      if (that.tabConfig?.disableAddTargetButton) buttonHeaderOption = [];

      obj.adgroup_sp_tab_keywordtargeting = {
        props: {
          headerOptions: buttonHeaderOption,
          enableDownload: false,
          enableManageColumns: !this.selections.length,
          manageColumnGridPosition: 4,
          headerContainerGridStyles: {
            'grid-template-columns': 'auto 1fr auto',
            display: this.selections.length ? 'none' : 'grid'
          },
          searchParams: {
            show: true,
            value: that.defaultSearchText,
            placeholder: 'Find a keyword',
            searchDimension: 'ams_cm_targeting_keyword_text'
          },
          rowSelection: {
            mode: 'multiple',
            onSelectionChanged: this.handleSelectionChange
          },
          rowHeight: 45
        }
      };
      return obj;
    },
    addCustomKeywordPanelConfig() {
      return spAddKeywordsRecommendationComponentConfig(this, 'Enter List');
    }
  },
  watch: {
    dateFilter: {
      handler(newVal) {
        this.initRequestParams.global = {
          ...this.initRequestParams.global,
          ...newVal
        };
        const initRequestParams = {
          ...this.initRequestParams,
          ...this.updateSavedWidgetRequestParams(this)
        };
        this.updateEntireRequestParams(initRequestParams);
      },
      immediate: true,
      deep: true
    },
    tableDataComponentKey: {
      handler(newVal) {
        const globalRequestParams = this.getGlobalRequestParams();
        this.updateGlobalRequestParams({ ...globalRequestParams, key: newVal });
      }
    }
  },
  created() {
    Vue.component('adgroup_sp_tab_keywordtargeting', dashboardServiceTable);
    Vue.component('customActionPanel', customActionPanel);
    Vue.component('instacart_cm_adgroup_fp_tab_keyword', dashboardServiceTable);

    const initRequestParams = {
      ...this.initRequestParams,
      ...this.updateSavedWidgetRequestParams()
    };
    this.updateEntireRequestParams(initRequestParams);
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.stateChangeRequestPayload = tabConfig?.stateChange?.requestPayload;
    this.bidChangeRequestPayload = tabConfig?.bidChange?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.$store.dispatch(this.tabConfig?.apiConfigs?.fetchAction, {
      entityId: this.entityId,
      entityType: this.entityConfig.entityType
    });
  },
  methods: {
    widgetParamsChanged(value) {
      const searchText = findDimensionValueFromWidgetParams(
        value,
        'adgroup_sp_tab_keywordtargeting',
        'ams_cm_targeting_keyword_text'
      );
      this.defaultSearchText = searchText?.dimensionValue || '';
    },
    updateSavedWidgetRequestParams() {
      const commonReqParams = commonRequestParams(this);
      updateExistingWidgetParams(
        'campaignCreationState',
        commonReqParams.adgroup_sp_tab_keywordtargeting,
        this.$route?.params?.entityId
      );
      return commonReqParams;
    },
    widgetParamsCollectionUpdated(paramsCollection) {
      if (paramsCollection?.adgroup_sp_tab_keyword_recommendation) {
        this.recommendationWidgetParams =
          paramsCollection?.adgroup_sp_tab_keyword_recommendation;
      } else {
        saveExistingWidgetParams(
          'campaignCreationState',
          paramsCollection,
          this.$route?.params?.entityId
        );
      }
    },
    metadataUpdated(data) {
      let recommendationMetadata = {};
      data.forEach((item) => {
        if (item.name === 'adgroup_sp_tab_keyword_recommendation') {
          recommendationMetadata = item;
        }
      });
      this.recommendationMetadata = recommendationMetadata;
    },
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
      this.checkForSuggestedBid();
    },
    transformBid(oldBid, selection) {
      let delta = parseFloat(this.bidValue.inputValue);
      if (this.bidValue.radioValue === 'set_new_bid') {
        if (this.bidValue.inputValue === 'YY') {
          return parseFloat(oldBid);
        }
        if (this.bidValue.inputValue === 'XX') {
          return parseFloat(selection.data?.ams_cm_targeting_bid_suggested);
        }
        return delta;
      }
      if (this.bidValue?.dropdownValue === 'percentage') {
        delta = oldBid * (delta / 100);
      }
      if (this.bidValue?.radioValue === 'increase_bid') {
        return parseFloat((oldBid + delta).toFixed(2));
      } else {
        return parseFloat((oldBid - delta).toFixed(2));
      }
    },
    onBidUpdates(updatedValues) {
      this.bidValue = updatedValues;
    },
    openActionsWorkspace(
      actionId,
      removeSelectionState,
      selectionLength,
      dropDownSelection,
      selections
    ) {
      if (actionId === 'editStatus') {
        this.callStateChange(dropDownSelection, selections);
      } else {
        if (actionId === 'adjustBids') {
          this.checkForSuggestedBid();
        }
        this[actionId] = true;
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.adjustBids = null;
      this.bidSuggestion = { show: false };
    },
    callStateChange(dropDownSelection, selections) {
      const payloadArr = [];
      for (const selection of selections) {
        if (
          selection?.data?.ams_cm_adgroup_status === dropDownSelection[0].value
        ) {
          this.$snackbar.open({
            message: 'No new state selected for one or more entities.',
            duration: 6000,
            buttonColor: '#f5d908',
            actionText: ''
          });
        } else {
          const payload = cloneDeep(this.stateChangeRequestPayload);
          const reqValues = {
            ':campaignId': this.parentEntityId,
            ':adgroupId': selection?.data?.adgroup_id,
            ':oldState': selection?.data?.ams_cm_targeting_status,
            ':state': dropDownSelection[0].value,
            ':pageUrl': window.location.href,
            ':keywordId': selection?.data?.keyword_id,
            ':keywordText':
              selection.data.ams_cm_targeting_keyword_text || 'NA',
            ':adgroupName': this.campaignInfo?.data?.name || 'NA',
            ':matchType': selection?.data?.ams_cm_targeting_match_type || 'NA'
          };
          for (const item in reqValues) {
            deepReplaceObjectValues(payload, item, reqValues[item]);
          }
          const isTarget = reqValues[':matchType'].includes('TARGETING');
          adjustPayloadForTargets(isTarget, payload, selection);
          payloadArr.push(payload);
        }
      }
      if (payloadArr.length) {
        this.updateMetadataAction(payloadArr, {
          singular: 'Keyword Status',
          plural: 'Keyword Statuses',
          stopFetchApi: true
        });
      } else {
        this.closeActionBar();
      }
    },
    onSaveBid() {
      const payloadArr = [];
      for (const selection of this.selections) {
        const oldBid = selection?.data?.ams_cm_targeting_base_bid || 0;
        const newBid = this.transformBid(oldBid, selection);
        const payload = cloneDeep(this.bidChangeRequestPayload);
        const reqValues = {
          ':campaignId': this.parentEntityId,
          ':adgroupId': this.entityId,
          ':oldBid': oldBid,
          ':newBid': newBid,
          ':pageUrl': window.location.href,
          ':keywordId': selection?.data?.keyword_id,
          ':keywordText': selection.data.ams_cm_targeting_keyword_text || 'NA',
          ':adgroupName': this.campaignInfo?.data?.name || 'NA',
          ':matchType': selection?.data?.ams_cm_targeting_match_type || 'NA'
        };
        for (const item in reqValues) {
          deepReplaceObjectValues(payload, item, reqValues[item]);
        }
        const isTarget = reqValues[':matchType'].includes('TARGETING');
        adjustPayloadForTargets(isTarget, payload, selection);
        payloadArr.push(payload);
      }
      this.updateMetadataAction(payloadArr, {
        singular: 'Keyword Bid',
        plural: 'Keyword Bids',
        stopFetchApi: true
      });
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: false,
        entityName
      });
      this.closeActionBar();
      this.showAddCustomKeywordPanel = false;
    },
    checkForSuggestedBid() {
      this.bidSuggestion = { show: false };
      this.bidValue = null;
      if (
        this.selections.length === 1 &&
        this.selections[0]?.data?.ams_cm_targeting_bid_suggested
      ) {
        this.bidSuggestion = {
          show: true,
          title: 'Amazon Suggested Bid',
          value:
            this.selections[0]?.data?.ams_cm_targeting_bid_suggested.toString(),
          currentBid:
            this.selections[0]?.data?.ams_cm_targeting_base_bid.toString(),
          multipleSuggestions: false
        };
      } else if (this.selections.length > 1) {
        let showBidSuggestion = true;
        this.selections.map((selection, index) => {
          if (!selection.data.ams_cm_targeting_bid_suggested) {
            showBidSuggestion = false;
          }
        });
        this.bidSuggestion = {
          show: showBidSuggestion,
          title: 'Amazon Suggested Bid',
          value: 'XX',
          currentBid: 'YY',
          multipleSuggestions: showBidSuggestion,
          currentBidNote: 'YY - Respective current bid in the table below',
          suggestedBidNote:
            'XX - Respective Amazon suggested bid in the table below'
        };
      } else {
        this.adjustBids = false;
      }
    },
    toggleRecCustomComponent(val) {
      this.showRecCustomComponent = val;
    },
    handleRecommendation(val) {
      this.selectedRecommendation = val;
    },
    // event listener to capture the action state in actions bar
    toggleCustomActionState(val) {
      this.isCustomActionStateVisible = val;
    },
    recommendationFilter(val, label) {
      if (val) {
        const [value] = Object.values(val);
        this.recFilter = { value, label };
        if (this.preferNoRecFilterList.includes(value)) {
          this.toggleRecCustomComponent(true);
        }
      } else {
        this.recFilter = '';
      }
    },
    addCustomKeywordButtonClick() {
      this.closeActionBar();
      this.showAddCustomKeywordPanel = true;
    },
    reloadComponent(boolReload = true) {
      if (boolReload) {
        this.$store.dispatch(
          this.tabConfig?.apiConfigs?.fetchTableDataComponentKeyGetter
        );
      }
    }
  }
};
</script>
<style lang="css">
.adGroupsTargetingKeyword .recommendations-container .header-container-cwc {
  padding: 0px;
}
.adGroupsTargetingKeyword
  .recommendations-container
  .custom_widget_body
  .header
  .custom-action-panel
  .custom-entity
  .split-table-section
  .action-header {
  padding-top: 0;
}
.adGroupsTargetingKeyword .min-height-card {
  min-height: 630px;
}
.adGroupsTargetingKeyword .header-container-cwc {
  padding: 2.4rem;
}
.adGroupsTargetingKeyword
  .count-class
  > .u-display-flex.u-text-overflow-ellipsis::after {
  content: 'recommendations';
  font-family: 'ProximaNova', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  margin-left: 0.4rem;
  margin-top: 0.5rem;
}
.adGroupsTargetingKeyword .order-1 {
  order: 1;
}
.adGroupsTargetingKeyword .order-2 {
  order: 2;
}
.adGroupsTargetingKeyword .order-3 {
  order: 3;
}
.adGroupsTargetingKeyword .order-4 {
  order: 4;
}
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
.entity-details-table-wrapper .dashboard-service-table-wrapper {
  margin: 0;
  padding-bottom: 0;
}
</style>

<style lang="css" scoped>
.min-height-card {
  min-height: 630px;
}
</style>
